<template>
<div>
    <b-card no-body >
        <b-tabs vertical card v-model="adminTabIndex" nav-class="float">
        <b-tab title="Владельцы" lazy>
            <owner-list ></owner-list>
        </b-tab>
        <b-tab title="Пользователи" lazy>
            <user-list ></user-list>
        </b-tab>
        <b-tab title="Объекты" lazy>
            <device-list></device-list>
        </b-tab>
        <b-tab title="Железяки" lazy>
            <hardware-list></hardware-list>
        </b-tab>
        <b-tab title="SIM" lazy>
            <sim-list></sim-list>
        </b-tab>
        <template #tabs-end>
            <b-nav-item @click.prevent="$emit('hide')">Закрыть</b-nav-item>
        </template>
        </b-tabs>
    </b-card>
</div>
</template>

<script>
import OwnerList from '@/components/OwnerList.vue';
import UserList from '@/components/UserList.vue';
import HardwareList from '@/components/HardwareList.vue';
import DeviceList from '@/components/DeviceList.vue';
import SimList from '@/components/SimList.vue';

export default {
    name: 'AdminView',
    components: { OwnerList, UserList, HardwareList, SimList, DeviceList, },
    data() {
        return {
            adminTabIndex: null,
        }
    },
    methods: {
    },
    filters: {
        iconpath(image) {
            return '/icons/'+image+'.png';
        },
        imagepath(image) {
            return '/images/'+image+'.png';
        },
    }
}
</script>

<style>
</style>
