<template>
<b-container fluid>
  <b-row>
    <b-col>
      <b-form-group label="Владелец">
        <owner-selector v-model="formData.owner_id" v-on:input="get_manager_routes"></owner-selector>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Наименование">
        <b-input type="text" v-model="formData.name"></b-input>
      </b-form-group>
    </b-col>
  </b-row>

  <b-card no-body >
    <b-tabs card>
      <b-tab title="Общие сведения">
        <b-row>
          <b-col>
            <b-form-group label="Тип Объекта">
              <b-form-select v-model="formData.kind" v-bind:options="kind_options"
                          text-field="description" value-field="alias">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Изображение">
              <b-button variant="outline-secondary" v-b-modal.image-modal>
                <b-img-lazy v-bind:src="formData.image | iconpath"></b-img-lazy>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Описание объекта">
          <b-input type="text" v-model="formData.decription"></b-input>
        </b-form-group>
        <b-form-group label="Реплика" label-for="checkbox-group-2" >
          <b-form-checkbox-group id="checkbox-group-2" v-model="formData.replica" :options="replica_options">
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="formData.state_freeze" switch> Заморожен </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Примечание:">
          <b-form-textarea v-model="formData.notes" rows="3"> </b-form-textarea>
        </b-form-group>
      </b-tab>
    
      <b-tab title="ТС">
        <b-row>
          <b-col cols="3">
            <b-form-group label="VIN">
              <b-input type="text" v-model="formData.vin"></b-input>
            </b-form-group>
            <b-form-group label="Рег.знак">
              <b-input type="text" v-model="formData.plate"></b-input>
            </b-form-group>
            <b-form-group label="Марка">
              <b-input type="text" v-model="formData.vendor"></b-input>
            </b-form-group>
            <b-form-group label="Модель">
              <b-input type="text" v-model="formData.model"></b-input>
            </b-form-group>
            <b-form-group label="Год выпуска">
              <b-input type="text" v-model="formData.year"></b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Серия,номер Свидетельства">
              <b-input type="text" v-model="formData.certify_number"></b-input>
            </b-form-group>
            <b-form-group label="Дата выдачи Свидетельства">
              <b-input type="text" v-model="formData.certify_date"></b-input>
            </b-form-group>
            <b-form-group label="Серия,номер ПТС">
              <b-input type="text" v-model="formData.pts_number"></b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Собственник ТС">
              <b-input type="text" v-model="formData.ownership"></b-input>
            </b-form-group>
            <b-form-group label="Основание владения ТС, срок действия док.">
              <b-input type="text" v-model="formData.ownership_doc"></b-input>
            </b-form-group>
            <b-form-group label="Характеристики ТС по видам перевозок">
              <b-input type="text" v-model="formData.transportkind"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      
      <b-tab title="Оборудование">
        <b-row>
          <b-col>
            <b-input-group prepend="Топливный бак, л">
              <b-input type="text" v-model="formData.fuel_tank"></b-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-form-checkbox switch v-model="formData.dut"> Датчик Уровня Топлива </b-form-checkbox>
            <b-form-checkbox switch v-model="formData.photo"> Фотокамера </b-form-checkbox>
          </b-col>
        </b-row>

        <card no-body v-if="formData.dut" header="Датчики Уровня Топлива">
          <b-tabs pills card vertical>
            <b-tab v-for="dut,dutidx in device_duts" :key="dutidx" :title="dut.name">
              <b-row>
                <b-col>
                  <b-form-group label="Наименование">
                    <b-input type="text" v-model="dut.name"></b-input>
                  </b-form-group>
                  <b-form-group label="Максимальный уровень">
                    <b-input type="number" v-model="dut.maxlevel"></b-input>
                  </b-form-group>
                  <b-form-group label="Длина">
                    <b-input type="number" v-model="dut.length"></b-input>
                  </b-form-group>
                  <b-form-group label="Интерфейс">
                    <b-form-select v-model="dut.iface" >
                      <b-form-select-option value="485">RS-485</b-form-select-option>
                      <b-form-select-option value="BT">Bluetooth</b-form-select-option>
                      <b-form-select-option value="FRQ">Частотный</b-form-select-option>
                      <b-form-select-option value="">Другой</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Дата монтажа">
                    <b-input type="date" v-model="dut.mounted"></b-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-table-simple hover striped bordered >
                    <caption>
                      Калибровка
                      <b-button-group class="float-right" >
                        <b-button variant="secondary" @click="calibr_sort(dutidx)"> Сортировать </b-button>
                        <b-button variant="primary" @click="calibr_append(dutidx)"> Добавить </b-button>
                      </b-button-group>
                    </caption>
                    <b-thead head-variant="dark">
                      <b-tr>
                        <b-th>Уровень</b-th>
                        <b-th>Значение</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="rec,idx in dut.calibration" :key="idx">
                        <b-td>
                          <b-input type="number" v-model="rec.level"></b-input>
                        </b-td>
                        <b-td class="d-flex align-items-center">
                          <b-input type="number" v-model="rec.value"></b-input>
                          <b-button variant="light" class="float-right" @click="calibr_remove(dutidx,idx)">
                            <b-icon variant="danger" icon="dash-square"></b-icon>
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-button variant="danger" class="float-right" @click="dut_remove(dutidx)">
                Удалить ДУТ
              </b-button>              
            </b-tab>
            <template #tabs-end>
              <b-nav-item role="presentation" @click.prevent="dut_append" href="#"><b>+</b></b-nav-item>
            </template>
            <template #empty>
              <div class="text-center text-muted">
                Нет ни одного датчика уровня топлива.
              </div>
            </template>
          </b-tabs>
        </card>
      </b-tab>
      
      <b-tab title="События">
        <b-form-checkbox switch v-model="formData.events_log"> Хранить протокол событий </b-form-checkbox>
      
        <b-row v-show="formData.events_log">
          <b-col>
            <b-form-group label="Звонок другу">
              <b-input type="tel" v-model="formData.events_phone" v-facade="'+7 (###) ### ## ##'"></b-input>
            </b-form-group>
            <div v-show="formData.events_phone">
              <b-form-group label="Маска событий">
                <b-form-checkbox-group v-model="formData.events_mask"
                                        :options="events_mask_options" switches>
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-group label="Маска объектов управления маршрутами"
                            v-show="formData.events_mask && formData.events_mask.includes('manager_routes_selective')">
                <b-form-checkbox-group v-model="formData.events_manager_routes"
                                        :options="manager_routes_options"
                                        text-field="name" value-field="id" switches>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-col>
            <b-col v-show="formData.photo">
                <b-form-checkbox v-model="formData.events_photo"> Запрос фотоснимка </b-form-checkbox>
                <div v-show="formData.events_photo">
                  <b-form-group label="Маска событий">
                    <b-form-checkbox-group v-model="formData.photo_events_mask"
                                            :options="events_mask_options" switches>
                    </b-form-checkbox-group>
                  </b-form-group>
                  <b-form-group label="Маска объектов управления маршрутами"
                                v-show="formData.photo_events_mask && formData.photo_events_mask.includes('manager_routes_selective')">
                    <b-form-checkbox-group v-model="formData.photo_events_manager_routes"
                                            :options="manager_routes_options"
                                            text-field="name" value-field="id" switches>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
            </b-col>
        </b-row>          
      </b-tab>

      <b-tab title="Монтаж">
        <device-mount v-model="device_mounts"></device-mount>
      </b-tab>
    </b-tabs>
  </b-card>
  
  <b-modal id="image-modal" hide-header hide-footer no-fade size="lg" v-on:shown="initImage">
    <b-row>
      <b-col style="max-height:80vh;overflow:auto;">
        <b-list-group >
          <b-list-group-item button active-class="active"
                          v-for="(grp,grpindex) in imageGroups"
                          v-bind:key="grpindex"
                          v-bind:active="grp == selectedImage.grp"
                          v-on:click="selectImageGroup(grp)">
            {{ grp }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col style="max-height:80vh;overflow:auto;" class="p-0 m-0">
        <b-container fluid class="m-0 p-0 d-flex flex-wrap">
          <b-button variant="outline-secondary" class="m-1 p-1"
                  v-for="(img,imgindex) in images[imageGroup]"
                  v-bind:key="imgindex"
                  v-bind:pressed="(img == selectedImage.img && imageGroup == selectedImage.grp)"
                  v-on:click="selectImage(img)">
            <b-img-lazy width="64" height="64" v-bind:src="`${imageGroup}/${img}` | icon64path"> </b-img-lazy>
          </b-button>
        </b-container>
      </b-col>
    </b-row>
  </b-modal>

  <b-form-group class="mt-2">
    <b-button squared variant="primary" @click="saveDevice" class="mx-1">Сохранить</b-button>
    <b-button squared variant="secondary" @click="$emit('editDone')" class="mx-1">Закрыть</b-button>
    <b-button v-if="deviceId" squared variant="danger" @click="deleteDevice" class="mx-1">Удалить</b-button>
  </b-form-group>

</b-container>
</template>

<script>
import OwnerSelector from "@/components/OwnerSelector.vue";
import DeviceMount from "@/components/DeviceMount.vue";

import { WS } from  '@/misc.js';

export default {
    name: "DeviceData",
    props: {
        deviceId: Number,
        defaultOwner: Number,
    },
    components: { OwnerSelector, DeviceMount },
    data() {
        return {
            replica_options: [{ value:'city',text:'СНПД'},
                              { value:'ends',text:'ЕНДС Москва'},
                              { value:'umgoroda',text:'Умные города'},
                              { value:'rnis',text:'РНИС АО'}],
            events_mask_options: [ { value: 'sensorVB', text: 'Движение' },
                                   { value: 'sensorNA', text: 'Отсутствие координат' },
                                   { value: 'sensorPWL', text:'Низкое питание' },
                                   { value: 'sensorME', text: 'Осн.двигатель' },
                                   { value: 'sensorSE', text: 'Всп.двигатель' },
                                   { value: 'sensorALR', text: 'Сигнализация' },
                                   { value: 'sensorSOS', text: 'Тревожная кнопка' },
                                   { value: 'manager_routes', text: 'Управление маршрутами' },
                                   { value: 'manager_routes_selective', text: 'Управление маршрутами выборочно' }],
            manager_routes_options: [],
            formData: {
                owner_id: this.defaultOwner,
                name: null, kind: null, image: null, decription: null,
                replica: [], state_freeze: false,
                vin: null, plate: null, vendor: null, model: null, year: null,
                certify_number: null, certify_date: null, pts_number: null,
                ownership: null, ownership_doc: null, transportkind: null,
                fuel_tank: null, dut: false, photo: false, events_log: false,
                events_phone: null, events_mask: [], events_manager_routes: [],
                events_photo: false, photo_events_mask: [], photo_events_manager_routes: [],
                notes: '',
            },
            images: {},
            imageGroup: null,
            selectedImage: {grp: null, img: null},
            kinds: [],
            device_duts: null,
            device_mounts: [],
        }
    },
    computed: {
        imageGroups() {
            return Object.keys(this.images);
        },
        kind_options() {
            return this.kinds.filter(item => {
                return item.condition == 'auto'
            });
        },
    },
    watch: {
        async formData_owner_id() {
            await this.get_manager_routes();
        },
    },
    async mounted() {
        try {
            if (this.deviceId) {
                // let device_data = await WS.request('device_data', this.deviceId)
                // for (let key in this.formData) {
                //     this.formData[key] = device_data[key];
                // }
                this.formData = await WS.request('device_data', this.deviceId);
                this.device_duts = await WS.request('device_dut', this.deviceId)
                this.device_mounts = await WS.request('device_mount', this.deviceId);
            }
            this.kinds = await WS.request('directory','device_kind');
            this.images = await WS.request('image_list');
            await this.get_manager_routes();
        } catch (err) {
            this.$bvModal.msgBoxOk(`device_data: ${err}`);
        }
    },
    methods: {
        async get_manager_routes() {
            try {
                this.manager_routes_options = await WS.request('manager_routes',
                                                               { owner_id: this.formData.owner_id,
                                                                 device_id: this.deviceId });
            } catch (err) {
                this.$bvModal.msgBoxOk(`manager_routes: ${err}`);
            }
        },

        selectImageGroup(grp) {
            this.imageGroup = grp;
        },

        selectImage(img) {
            this.selectedImage = { grp: this.imageGroup, img };
            this.formData.image = `${this.selectedImage.grp}/${this.selectedImage.img}`;
            this.$nextTick(() => {
                this.$bvModal.hide('image-modal');
            });
        },

        initImage() {
            if (this.formData.image) {
                let [ grp,img ] = this.formData.image.split('/');
                this.selectedImage = { grp,img };
                this.imageGroup = this.selectedImage.grp;
            }
        },
        async saveDevice() {
            try {
                let result = await WS.request('device_update', { id: this.deviceId, data: this.formData })
                await WS.request('device_dut_update', { id: this.deviceId, data: this.device_duts })
                this.$emit('editDone',result);
            } catch (err) {
                this.$bvModal.msgBoxOk(`device_update: ${err}`);
            }
        },

        async deleteDevice() {
            try {
                let yesNo = await this.$bvModal.msgBoxConfirm('Удалить объект? Вы уверены?', 
                                                              { okTitle:"Да", cancelTitle:"Нет" });
                if (yesNo) {
                    await WS.request('device_delete', this.deviceId);
                    this.$emit('deleteDone', this.deviceId);
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },

        dut_append() {
          this.device_duts.push({ name: 'Новый ДУТ', calibration: [] });
        },

        dut_remove(index) {
          this.device_duts.splice(index, 1);
        },

        calibr_append(dutindex) {
          this.device_duts[dutindex].calibration.push({ level: 0, value: 0 });
        },

        calibr_remove(dutindex, index) {
          this.device_duts[dutindex].calibration.splice(index, 1);
        },

        calibr_sort(dutindex) {
          console.log('calibration sorting ')
          this.device_duts[dutindex].calibration.sort((a,b) => {
            return a.level > b.level
          })
        },
    },
    filters: {
        iconpath(image) {
            return '/icons/'+image+'.png';
        },
        icon64path(image) {
            return '/icons64/'+image+'.png';
        }
    }
}
</script>

<style>
</style>
