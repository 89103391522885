<template>
<b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
  <b-form-group label="Владелец" label-cols="3" label-align="right">
    <owner-selector v-model="formData.owner_id"></owner-selector>
  </b-form-group>
  
  <b-form-group label="ФИО">
    <b-input type="text" v-model="formData.name"></b-input>
  </b-form-group>
  
  <b-form-row>
    <b-col>
      <b-form-group label="Логин">
        <b-input type="text" v-model="formData.login"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Пароль">
        <b-input type="password" v-model="formData.password"></b-input>
      </b-form-group>
    </b-col>
  </b-form-row>
  
  
  <b-form-group label="Примечание">
    <b-form-textarea v-model="formData.notes" rows="3"> </b-form-textarea>
  </b-form-group>
  
  <b-button type="submit" variant="primary" squared>Сохранить</b-button>
</b-form>
</template>

<script>
import { WS } from  '@/misc.js';
import OwnerSelector from "@/components/OwnerSelector";

export default {
    name: "UserData",
    components: { OwnerSelector },
    props: { userId: Number },
    data () {
        return {
            formData: {
                name: '',
                login: '',
                password: '',
                owner_id: null,
                notes: '',
            },
        }
    },
    async mounted() {
        try {
            if (this.userId) {
                this.formData = await WS.request('user_data', this.userId);
            }
        } catch (err) {
            this.$bvModal.msgBoxOk(err);
        }
    },
    methods: {
        async onSubmit() {
            try {
                let userId = await WS.request('user_update',{ id:this.userId, data:this.formData })
                this.$emit('done',userId);
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },
    }
}
</script>

<style scoped>

</style>
