<template>
<b-container fluid>
    <b-row>
        <b-col cols="4">Наименование:</b-col>
        <b-col>{{currentInfo.name}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Номер:</b-col>
        <b-col>{{currentInfo.number}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Идентификатор:</b-col>
        <b-col>{{currentInfo.identifier}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Фиксация:</b-col>
        <b-col>{{currentInfo.lastnav|timestamp}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Координаты:</b-col>
        <b-col>{{currentInfo.coords}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Скорость:</b-col>
        <b-col>{{currentInfo.speed}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Датчики:</b-col>
        <b-col>{{currentInfo.sensors}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Связь:</b-col>
        <b-col>{{currentInfo.lastdata|timestamp}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Телеметрия:</b-col>
        <b-col>{{currentInfo.telemetry}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">ДУТ</b-col>
        <b-col>{{currentInfo.duts}}</b-col>
    </b-row>
    <b-row>
        <b-col cols="4">Время:</b-col>
        <b-col>{{currentInfo.time_stamp|datetime}}</b-col>
    </b-row>
</b-container>
</template>

<script>
import { WS } from '@/misc.js';

export default {
    name: 'CurrentInfo',
    props: {
        deviceId: Number,
    },
    data() {
        return {
            currentInfo: null,
        }
    },
    async mounted() {
        await this.getCurrentInfo();
    },
    methods: {
        async getCurrentInfo() {
            try {
                this.currentInfo = await WS.request('current_info', this.deviceId);
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },
    },
    filters: {
        iconpath(image) {
            return '/icons/'+image+'.png';
        },
        imagepath(image) {
            return '/images/'+image+'.png';
        },
    }
}
</script>

<style>
</style>
