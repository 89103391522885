<template>
<b-dropdown v-bind:text="valueText" :disabled="disabled" block >
  <b-dropdown-form>
    <b-form-select v-model="owner_kind" :options="owner_kind_options" size="sm" > </b-form-select>
  </b-dropdown-form>
  <b-dropdown-form v-if="searched">
    <b-form-input v-model="search" size="sm" placeholder="поиск..." ></b-form-input>
  </b-dropdown-form>
  <b-dropdown-divider v-if="searched"></b-dropdown-divider>
  
  <b-dropdown-item v-if="emptyvalue" v-on:click="$emit('input',null)" :size="size">
    <em>Пусто</em>
  </b-dropdown-item>
  <b-dropdown-divider v-if="emptyvalue" ></b-dropdown-divider>
  <div class="mydropdownitems">
    <b-dropdown-item v-for="opt in filteredOptions" :key="opt.id" :active="opt.id === value"
                     @click="$emit('input', opt.id)" :size="size">
      {{opt.name}}
    </b-dropdown-item>
  </div>
</b-dropdown>
</template>

<script>
import { WS } from  '@/misc.js';

    export default {
        name: "OwnerSelector",
        props: {
            value: Number,
            emptyvalue: Boolean,
            searched: Boolean,
            disabled: { type: Boolean, default: false },
            size: String,
        },
        data: () => ({
            owner_kind_options: [ { value: 'regular', text: 'Обычный' },
                                  { value: 'shuttle', text: 'Маршрутки' },
                                  { value: 'private', text: 'Частники' },
                                  { value: 'ship', text: 'Флотский' },
                                ],
            owner_kind: null,
            options: [],
            search: ''
        }),
        computed: {
            filteredOptions() {
                return this.options.filter(item => {
                    return ((item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) &&
                            (this.owner_kind === null || item.kind == this.owner_kind));
                });
            },
            valueText() {
                let opt = this.options.find(item => item.id == this.value);
                return opt ? opt.name : 'Неизвестный';
            }
        },
        mounted() {
            WS.request('owners')
                .then((data) => {
                    this.options = data;
                })
                .catch(err => {
                    alert(err);
                });
        },
    }
</script>

<style scoped>
.selector-droplist {
    width: 400px;
}
.mydropdownitems {
    width: 400px;
    overflow-y: scroll;
    max-height: 70vh;
}
</style>
