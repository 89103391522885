<template>
<b-container fluid>
  <b-row>
    <b-col md="2">
      <b-form-group label="Наименование" label-size="sm">
        <b-form-input v-model="search" debounce="1000" size="sm"> </b-form-input>
      </b-form-group>
      <b-button size="sm" variant="outline-primary" squared @click="onNewClick"> Добавить </b-button>
    </b-col>
    <b-col>
      <b-table striped hover light responsive :fields="fields" :items="owners" :filter="search"
                @row-clicked="onRowClick">
        <template v-slot:row-details="row">
          <b-card>
            <owner-data class="p-0 m-0" :ownerId="row.item.id"
                        @editDone="onEditDone(row,$event)" @deleteDone="onDeleteDone(row)">
            </owner-data>
          </b-card>
        </template>
      </b-table>

      <b-card v-if="appendNew" ref="newOwnerCard">
        <owner-data class="p-0 m-0" @editDone="onEditDone(null,$event)"> </owner-data>
      </b-card>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import Vue from 'vue';
import OwnerData from "@/components/OwnerData.vue";
import { WS } from  '@/misc.js';

export default {
  name: "OwnerList",
  components: { OwnerData },
  data() {
    return {
      owners: [],
      search: '',
      fields: [
          { key: 'name', label: 'Наименование' },
          { key: 'contract_number', label: 'Договор' },
          { key: 'contract_date', label: 'Дата дог.' },
          { key: 'tariff_plan', label: 'Тариф.план' },
          { key: 'saldo', label: 'Долг' },
          { key: 'notes', label: 'Примечание' },
      ],
      appendNew: false
    }
  },

  async mounted() {
    await this.refresh();
  },

  methods: {
    async refresh() {
      try {
        this.owners = await WS.request('owner_list');
        this.owners.forEach(item => {
          Vue.set(item, '_showDetails', false);
        });
      } catch (err) {
        this.$bvModal.msgBoxOk(err);
      }
    },

    async onEditDone(row, ownerId) {
      if (ownerId) {
        try {
          let ownerData = await WS.request('owner_list_one', ownerId);
          Vue.set(ownerData, '_showDetails', false);
          let ownerIndex = this.owners.findIndex(item => item.id == ownerId);
          if (ownerIndex >= 0) {
            this.owners.splice(ownerIndex, 1, ownerData);
          } else {
            this.owners.splice(this.owners.length+1, 0, ownerData);
          }
        } catch (err) {
          this.$bvModal.msgBoxOk(err);
        }
      }
      if (row) {
        row.toggleDetails();
      }
      this.appendNew = false;
    },

    onDeleteDone(row) {
      let ownerIndex = this.owners.findIndex(item => item.id == row.item.id);
      if (ownerIndex >= 0) {
        this.owners.splice(ownerIndex, 1);
      }
    },
    
    onRowClick(item) {
      item._showDetails = !item._showDetails;
    },
    
    onNewClick() {
      this.appendNew = !this.appendNew;
      if (this.appendNew) {
        this.$nextTick(() => {
          const newRowRef = this.$refs.newOwnerCard;
          newRowRef.focus();
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
