<template>
<b-container fluid>

  <b-row>
    <b-col>
      <b-form-group>
        <owner-selector v-model="filter.owner_id" @input="refresh" emptyvalue size="sm"></owner-selector>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group>
        <b-form-input v-model="filter.name" type="search" size="sm" debounce="1000"
                      placeholder="Объект" @update="refresh">
        </b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group>
        <b-form-input v-model="filter.hardware" type="search" size="sm" debounce="1000"
                      placeholder="Номер прибора" @update="refresh">
        </b-form-input>
      </b-form-group>
    </b-col>
  </b-row>

  <b-table striped hover light responsive :fields="fields" :items="items" @row-clicked="onRowClick">
    <template v-slot:row-details="row">
      <b-card>
        <device-data class="p-0 m-0" :deviceId="row.item.id" :default-owner="filter.owner_id"
                     @editDone="onEditDone(row,$event)" @deleteDone="onDeleteDone(row)">
        </device-data>
      </b-card>
    </template>
  </b-table>

  <b-button variant="outline-primary" squared @click="onNewClick"> Добавить </b-button>

  <b-card v-if="appendNew">
    <device-data class="p-0 m-0" :default-owner="filter.owner_id"
                 @editDone="onEditDone(null,$event)" ref="newdevice">
    </device-data>
  </b-card>
</b-container>
</template>

<script>
import Vue from 'vue';

import OwnerSelector from "@/components/OwnerSelector.vue";
import DeviceData from "@/components/DeviceData.vue";
import { WS } from  '@/misc.js';

export default {
    name: "DeviceList",
    components: { OwnerSelector, DeviceData },
    data() {
        return {
            items: [],
            fields: [
                { key: 'name', label: 'Наименование' },
                { key: 'owner_name', label: 'Владелец' },
                { key: 'number', label: 'Прибор' },
            ],
            filter: { owner_id: null, name: null, hardware: null },
            appendNew: false,
        }
    },
    mounted() {
    },
    methods: {
        async refresh() {
            try {
                this.items = await WS.request('device_list', { filter: this.filter });
                this.items.forEach(item => {
                    Vue.set(item, '_showDetails', false);
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },

        async onEditDone(row, deviceId) {
            if (deviceId) {
                try {
                    let data = await WS.request('device_list_one', deviceId);
                    Vue.set(data, '_showDetails', false);
                    let index = this.items.findIndex(item => item.id == deviceId);
                    if (index >= 0) {
                        this.items.splice(index, 1, data);
                    } else {
                        this.items.splice(this.items.length+1, 0, data);
                    }
                } catch (err) {
                  this.$bvModal.msgBoxOk(`${err}`);
                }
            }
            if (row) {
                row.toggleDetails();
            }
            this.appendNew = false;
        },
        
        onDeleteDone(row) {
            let index = this.items.findIndex(item => item.id == row.item.id);
            if (index >= 0) {
                this.items.splice(index, 1);
            }
        },

        onRowClick(item) {
            item._showDetails = !item._showDetails;
        },
        
        onNewClick() {
            this.appendNew = !this.appendNew;
            if (this.appendNew) {
                this.$nextTick(() => {
                    const newRowRef = this.$refs.newdevice;
                    newRowRef.focus();
                });
            }
        },

    },
}
</script>

<style>

</style>
