<template>
<b-container fluid>
    <b-row>
      <b-col md="2">
        <owner-selector v-model="ownerId" v-on:input="refresh"> </owner-selector>
        <b-form-group>
          <b-form-input v-model="search" placeholder="Наименование" debounce="500" size="sm"> </b-form-input>
        </b-form-group>
        <b-button size="sm" variant="outline-primary" squared 
                  v-on:click="appendNew=!appendNew">
          Добавить
        </b-button>
      </b-col>
      <b-col>
        <b-table small striped hover light responsive sticky-header no-border-collapse class="mytable"
                 v-bind:fields="fields" v-bind:items="users" v-bind:filter="search">
          <template v-slot:cell(actions)="row">
            <b-button-group>
              <b-button @click="userDelete(row)" size="sm" variant="outline-primary" squared> Удалить </b-button>
              <b-button @click="userData(row)" size="sm" variant="outline-primary" squared> Изменить </b-button>
              <b-button @click="userPrivileges(row)" size="sm" variant="outline-primary" squared> Доступ </b-button>
            </b-button-group>
          </template>
          
          <template v-slot:row-details="row">
            <b-card>
              <user-data v-if="row.item.detailShow == 'data'"
                    v-bind:user-id="row.item.id"
                    v-on:done="userData(row,$event)">
              </user-data>
              <user-privileges v-if="row.item.detailShow == 'priv'"
                               v-bind:user-id="row.item.id"
                               v-on:done="userPrivileges(row,$event)">
              </user-privileges>
            </b-card>
          </template>
        </b-table>
        <b-card v-if="appendNew">
          <user-data @done="userData(null,$event)"> </user-data>
        </b-card>
      </b-col>
    </b-row>
</b-container>
</template>

<script>
import UserData from "@/components/UserData.vue";
import UserPrivileges from "@/components/UserPrivileges.vue";
import { WS } from  '@/misc.js';
import OwnerSelector from "@/components/OwnerSelector.vue";

export default {
    name: "UserList",
    components: { UserData, UserPrivileges, OwnerSelector },
    data() {
        return {
            ownerId: null,
            users: [],
            search: '',
            fields: [
                { key: 'name', label: 'Ф.И.О.' },
                { key: 'login', label: 'Логин' },
                { key: 'owner_name', label: 'Владелец' },
                { key: 'notes', label: 'Примечание' },
                { key: 'actions', label: 'Действия', stickyColumn: true },
            ],
            appendNew: false
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.users = await WS.request('user_list', { owner_id: this.ownerId });
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },
        async refreshOne(userId) {
            try {
                let userData = await WS.request('user_list_one', userId);
                let userIndex = this.users.findIndex(item => item.id == userId);
                if (userIndex >= 0) {
                    this.users.splice(userIndex, 1, userData);
                } else {
                    this.users.splice(this.users.length+1, 0, userData);
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },
        userPrivileges(row, userId) {
            if (row.item.detailShow == 'priv' || !row.item.detailShow) {
                row.toggleDetails();
            }
            this.$set(row.item, 'detailShow', row.item.detailShow == 'priv' ? null : 'priv')
            if (userId) {
                this.refreshOne(userId);
            }
        },
        userData(row, userId) {
            if (row) {
                if (row.item.detailShow == 'data' || !row.item.detailShow) {
                    row.toggleDetails();
                }
                this.$set(row.item, 'detailShow', row.item.detailShow == 'data' ? null : 'data')
            } else {
                this.appendNew = false;
            }
            if (userId) {
                this.refreshOne(userId);
            }
        },
        async userDelete(row) {
            try {
                let yesNo = await this.$bvModal.msgBoxConfirm('Вы уверены?',
                                                              { okTitle:"Да", cancelTitle:"Нет" });
                if (yesNo) {
                    await WS.request('user_delete',row.item.id);
                    let userIndex = this.userList.findIndex(item => item.id == row.item.id);
                    if (userIndex >= 0) {
                        this.userList.splice(userIndex, 1);
                    }
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },        
    }
}
</script>

<style scoped>
.mytable {
    overflow-y: scroll;
    max-height: 85vh;
}
</style>
