<template>
<b-container fluid>
    <b-row>
        <b-col>
            <b-form-group label="Владелец">
                <owner-selector v-model="formData.owner_id"></owner-selector>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="Номер">
                <b-input type="text" v-model="formData.number"></b-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="Идентификатор">
                <b-input type="text" v-model="formData.identifier"></b-input>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group label="Производитель">
                <b-form-select v-model="formData.vendor" v-bind:options="vendorOptions"
                               text-field="description" value-field="alias">
                </b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="Продукт">
                <b-form-select v-model="formData.product" v-bind:options="productOptions"
                                text-field="description" value-field="alias">
                </b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="Проект">
                <b-form-select v-model="formData.project" v-bind:options="projectOptions" >
                </b-form-select>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-checkbox v-model="formData.shake"> Вибратор </b-form-checkbox>
        </b-col>
        <b-col>
            <b-form-group label="SIM карта 1">
                <sim-selector emptyvalue v-model="formData.sim1" v-bind:hardware-id="hardwareId"> </sim-selector>
            </b-form-group>
            <b-form-group label="SIM карта 2">
                <sim-selector emptyvalue v-model="formData.sim2" v-bind:hardware-id="hardwareId"> </sim-selector>
            </b-form-group>
        </b-col>
        <b-col>
        </b-col>
    </b-row>
    <b-form-group label="Примечание">
        <b-form-textarea v-model="formData.notes" rows="3"> </b-form-textarea>
    </b-form-group>

    <b-form-group>
      <b-button squared variant="primary" @click="saveHardware" class="mx-1">Сохранить</b-button>
      <b-button squared variant="secondary" @click="$emit('editDone')" class="mx-1">Закрыть</b-button>
      <b-button v-if="hardwareId" squared variant="danger" @click="deleteHardware" class="mx-1">Удалить</b-button>
    </b-form-group>
</b-container>
</template>

<script>
import { WS } from  '@/misc.js';
import OwnerSelector from "@/components/OwnerSelector.vue";
import SimSelector from "@/components/SimSelector.vue";

export default {
    name: "HardwareData",
    components: { OwnerSelector, SimSelector },
    props: { hardwareId: Number },
    data() {
        return {
            projectOptions: [
                { value: 'auto', text: 'Автомобильный' },
                { value: 'vs', text: 'Видеонаблюдение' },
                { value: 'pi', text: 'Raspberry навигатор' },
            ],
            vendorOptions: [],
            hw_products: [],
            formData: {
                owner_id: null,
                number: null,
                identifier: null,
                vendor: null,
                product: null,
                project: null,
                shake: null,
                sim1: null,
                sim2: null,
                notes: null,
            },
        }
    },
    computed: {
        productOptions() {
            return this.hw_products.filter(item => {
                return item.condition == this.formData.vendor
            });
        },
    },
    async mounted() {
        try {
            this.vendorOptions = await WS.request('directory','hardware_vendor');
            this.hw_products = await WS.request('directory','hardware_product');
            if (this.hardwareId) {
                let data = await WS.request('hardware_data', this.hardwareId);
                for (let key in this.formData) {
                    this.formData[key] = data[key];
                }
            }
        } catch (err) {
            this.$bvModal.msgBoxOk(`${err}`);
        }
    },
    methods: {
        async saveHardware() {
            try {
                let result = await WS.request('hardware_update',
                                              { id: this.hardwareId, data: this.formData });
                this.$emit('editDone',result);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },

        async deleteHardware() {
            try {
                let yesNo = await this.$bvModal.msgBoxConfirm('Вы уверены?', { okTitle:"Да", cancelTitle:"Нет" });
                if (yesNo) {
                    await WS.request('hardware_delete', this.hardwareId);
                    this.$emit('deleteDone', this.hardwareId);
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
        },

    },
    filters: {
    }
}
</script>

<style scoped>

</style>
