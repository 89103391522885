<template>
<b-container fluid>
    <b-row>
        <b-col md="2">
            <b-form-group>
                <owner-selector v-model="filter.device_owner_id" emptyvalue @input="refresh"></owner-selector>
            </b-form-group>
            <b-form-group label="Номер прибора" label-size="sm">
                <b-form-input v-model="filter.number" type="search" size="sm" debounce="1000" @update="refresh"> 
                </b-form-input>
            </b-form-group>
            <b-form-group label="Идентификатор" label-size="sm">
                <b-form-input v-model="filter.identifier" type="search" size="sm" debounce="1000" @update="refresh"> 
                </b-form-input>
            </b-form-group>
            <b-form-group label="Объект" label-size="sm">
                <b-form-input v-model="filter.device" type="search" size="sm" debounce="1000" @update="refresh"> 
                </b-form-input>
            </b-form-group>
            
            <b-button variant="outline-primary" squared size="sm" @click="onNewClick"> Добавить </b-button>
        </b-col>
        
        <b-col class="px-1">
            <b-table-lite striped hover light responsive :fields="fields" :items="items" @row-clicked="onRowClick">
                <template v-slot:row-details="row">
                    <b-card>
                        <hardware-data class="p-0 m-0" :hardware-id="row.item.id"
                                        @editDone="onEditDone(row,$event)" @deleteDone="onDeleteDone(row)">
                        </hardware-data>
                    </b-card>
                </template>
            </b-table-lite>
        
            <b-card v-if="appendNew">
                <hardware-data :defaultOwner="filter.owner_id" @editDone="onEditDone(null,$event)" ref="newhardware"> 
                </hardware-data>
            </b-card>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import Vue from 'vue';

import OwnerSelector from "@/components/OwnerSelector.vue";
import HardwareData from "@/components/HardwareData.vue";
import { WS } from  '@/misc.js';

export default {
    name: "HardwareList",
    props: {
        defaultOwner: Number,
    },
    components: { OwnerSelector, HardwareData },
    data() {
        return {
            search: '',
            items: [],
            fields: [
                { key: 'number', label: 'Номер' },
                { key: 'identifier', label: 'Идентификатор' },
                { key: 'device_name', label: 'Объект' },
                { key: 'device_owner_name', label: 'Владелец объекта' },
            ],
            appendNew: false,
            filter: { number: null,
                      device_owner_id: this.defaultOwner,
                      identifier: null,
                      device: null },
        }
    },

    methods: {
        async refresh() {
            try {
                this.items = await WS.request('hardware_list', { filter: this.filter });
                this.items.forEach(item => {
                    Vue.set(item, '_showDetails', false);
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`hardware_list: ${err}`);
            }
        },

        async onEditDone(row, hardware_id) {
            if (hardware_id) {
                try {
                    let data = await WS.request('hardware_list_one', hardware_id);
                    Vue.set(data, '_showDetails', false);
                    let index = this.items.findIndex(item => item.id == hardware_id);
                    if (index >= 0) {
                        this.items.splice(index, 1, data);
                    } else {
                        this.items.splice(this.items.length+1, 0, data);
                    }
                } catch (err) {
                    alert(err);
                }
            }
            if (row) {
                row.toggleDetails();
            }
            this.appendNew = false;
        },
        
        onDeleteDone(row) {
            let index = this.items.findIndex(item => item.id == row.item.id);
            if (index >= 0) {
                this.items.splice(index, 1);
            }
        },
        
        onRowClick(item) {
            item._showDetails = !item._showDetails;
        },
        
        onNewClick() {
            this.appendNew = !this.appendNew;
            if (this.appendNew) {
                this.$nextTick(() => {
                    const newRowRef = this.$refs.newhardware;
                    newRowRef.focus();
                });
            }
        },
  }
}
</script>

<style scoped>

</style>
