<template>
<b-container fluid>
  <b-form-group label="Системные привилегии">
    <b-form-checkbox-group v-model="userPrivileges" v-bind:options="privileges"
                           text-field="name" value-field="alias" switches>
    </b-form-checkbox-group>
  </b-form-group>
  
  <b-form-group label="Объекты">
    <b-row>
      <b-col cols="8">
        <b-table-simple hover caption-top responsive small striped light bordered
                        style="max-height:300px;overflow: auto;">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Владелец</b-th>
              <b-th>Объект</b-th>
              <b-th>Привилегии</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in userDevices" :key="item.id" >
              <b-td>{{ item.owner_name }}</b-td>
              <b-td>{{ item.name }}</b-td>
              <b-td>
                <b-button size="sm" @click="showDevicePrivs(item)">{{ item.privs }}</b-button>
                <b-button size="sm" @click="delUserDevice(item.id)" variant="danger" class="float-right">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col>
        <b-form-select v-model="selectedOwner" v-bind:options="owners"
                       text-field="name" value-field="id">
        </b-form-select>
        <b-form-select v-model="selectedDevice" v-bind:options="devices"
                       text-field="name" value-field="id">
        </b-form-select>
        <b-button @click="addUserDevice">Добавить</b-button>
      </b-col>
    </b-row>
  </b-form-group>
  
  <b-modal id="devprivs-modal" @hidden="hideDevicePrivs" hide-header ok-only>
    <b-form-group label="Объектные привилегии">
      <b-form-checkbox-group v-model="editDevice.privileges" v-bind:options="devicePrivileges"
                             text-field="name" value-field="alias"
                             stacked switches>
      </b-form-checkbox-group>
    </b-form-group>
  </b-modal>

  <b-button variant="primary" @click="onSubmit">Сохранить</b-button>
</b-container>
</template>

<script>
import { WS } from  '@/misc.js';

export default {
    name: "UserPrivileges",
    props: { userId: Number },
    data () {
        return {
            devicePrivileges: [],
            userPrivileges: [],
            userDevices: [],
            privileges: [],
            owners: [],
            devices: [],
            selectedOwner: null,
            selectedDevice: null,
            editDevice: {},
            lastDP: [],
            lastDPs: '',
        }
    },
    watch: {
        async selectedOwner() {
            try {
                this.devices = await WS.request('device_list', { owner_id: this.selectedOwner });
            } catch (err) {
                this.$bvModal.msgBoxOk(`device_list:${err}`);
            } 
        },
    },
    async mounted() {
        try {
            let data = await WS.request('user_privileges', this.userId);
            this.privileges = data.types_privileges;
            this.devicePrivileges = data.types_device_privileges;
            this.userPrivileges = data.privileges;
            data.device_privileges.forEach((dev) => {
                dev.privs = '';
                this.devicePrivileges.forEach((item) => {
                    if (dev.privileges.includes(item.alias)) {
                        dev.privs += item.code;
                    }
                });
            });
            this.userDevices = data.device_privileges;
            this.owners = await WS.request('owner_list');
        } catch (err) {
            this.$bvModal.msgBoxOk(`user_privileges:${err}`);
        }
    },
    methods: {
        async onSubmit() {
            try {
                await WS.request('user_privileges_update',
                                 { id:this.userId,
                                   privileges: this.userPrivileges,
                                   device_privileges: this.userDevices });
                this.$emit('done',this.userId);
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },
        addUserDevice() {
            let dev = this.devices.find((item) => item.id == this.selectedDevice);
            let own = this.owners.find((item) => item.id == this.selectedOwner);
            this.userDevices.push({ id: this.selectedDevice,
                                    name: dev.name, owner_name: own.name,
                                    privileges: this.lastDP, privs: this.lastDPs });
        },
        showDevicePrivs(dev) {
            this.editDevice = dev;
            this.$bvModal.show("devprivs-modal");
        },
        hideDevicePrivs() {
            this.editDevice.privs = '';
            this.devicePrivileges.forEach((item) => {
                if (this.editDevice.privileges.includes(item.alias)) {
                    this.editDevice.privs += item.code;
                }
            });
            this.lastDP = this.editDevice.privileges;
            this.lastDPs = this.editDevice.privs;
        },
    },
}
</script>

<style scoped>

</style>
