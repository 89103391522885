//import '@babel/polyfill'
//import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/mask'
import App from './components/App.vue'
import VueMeta from 'vue-meta';
// import FontAwesomeIcon from '@fortawesome/vue-fontawesome';

Vue.use(VueMeta)
// Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
