<template>
<b-container fluid class="my-2">
  <b-table-simple hover caption-top responsive small striped light bordered>
    <caption>
      Список приборов
    </caption>
    <b-thead head-variant="dark">
      <b-tr>
        <b-th>Номер прибора</b-th>
        <b-th >Идентификатор</b-th>
        <b-th >Смонтирован</b-th>
        <b-th >Демонтирован</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="hw in value">
        <b-tr :key="hw.u_key" @click="open_mount(hw,$event)" :class="{ 'table-primary': hw == mount }">
          <b-td>{{ hw.hardware_number }}</b-td>
          <b-td>{{ hw.hardware_ident }}</b-td>
          <b-td>{{ hw.time_mount | formatDate }}</b-td>
          <b-td>{{ hw.time_dismount | formatDate }}</b-td>
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>

  <div v-if="showModal" class="container-fluid shadow-sm pt-3">
    <b-table-simple small bordered>
      <caption>
        Цифровые входа
        <b-button variant="outline-primary" class="float-right" v-on:click="din_append">
          Добавить
        </b-button>
      </caption>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th> Вход </b-th>
          <b-th> Сенсор </b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(dpin,index) in mount.dins" v-bind:key="index">
          <b-td>
            <b-form-select v-model="dpin.din" v-bind:options="din_options"> </b-form-select>
          </b-td>
          <b-td>
            <b-form-select v-model="dpin.sensor" v-bind:options="sensor_options"> </b-form-select>
          </b-td>
          <b-td class="align-middle">
            <b-button variant="outline-danger" class="float-right"
                      v-on:click="din_remove(index)">
              Убрать
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
            
    <b-table-simple small bordered>
      <caption>
        Аналоговые входа
        <b-button variant="outline-primary" class="float-right" v-on:click="ain_append">Добавить</b-button>
      </caption>
      <b-thead head-variant="light">
        <b-tr>
          <b-th scope="col">Вход</b-th>
          <b-th scope="col">Тип</b-th>
          <b-th scope="col">Сенсор</b-th>
          <b-th scope="col">Уровень</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(apin,index) in mount.ains" v-bind:key="index">
          <b-td>
            <b-form-select v-model="apin.ain" v-bind:options="ain_options"> </b-form-select>
            <b-form-checkbox v-model="apin.leveled" switch>
              {{ apin.leveled ? 'Уровневый' : 'Аналоговый' }}
            </b-form-checkbox>
          </b-td>
          <b-td>
            <b-form-select v-model="apin.type" v-bind:options="mount.ain_types"> </b-form-select>
          </b-td>
          <b-td>
            <div v-if="apin.leveled">
              <b-form-select v-model="apin.sensor_lo" v-bind:options="sensor_options"> </b-form-select>
              <b-form-select v-model="apin.sensor_hi" v-bind:options="sensor_options"> </b-form-select>
            </div>
            <div v-else>
              <b-form-select v-model="apin.sensor" v-bind:options="sensor_options"> </b-form-select>
            </div>
          </b-td>
          <b-td>
            <div v-if="apin.leveled">
              <b-input type="number" v-model="apin.calibrate_lo"></b-input>
              <b-input type="number" v-model="apin.calibrate_hi"></b-input>
            </div>
          </b-td>
          <b-td class="align-middle">
            <b-button variant="outline-danger" class="float-right" v-on:click="ain_remove(index)">Убрать</b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-table-simple small bordered>
      <caption>
        Частотные входа
        <b-button variant="outline-primary" class="float-right" v-on:click="fin_append">Добавить</b-button>
      </caption>
      <b-thead class="thead-light">
        <b-tr>
          <b-th>Вход</b-th>
          <b-th>Сенсор</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(fpin,index) in mount.fins" v-bind:key="index" >
          <b-td>
            <b-form-select v-model="fpin.fin" v-bind:options="fin_options"> </b-form-select>
          </b-td>
          <b-td>
            <b-form-select v-model="fpin.sensor" v-bind:options="mount.fin_targets"> </b-form-select>
          </b-td>
          <b-td class="align-middle">
            <b-button variant="outline-danger" class="float-right" v-on:click="fin_remove(index)">Убрать</b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
            
    <div class="pb-3">
      <b-button variant="success" v-on:click="savedata">Сохранить изменения</b-button>
    </div>
  </div> <!-- showModal -->
  
</b-container>
</template>

<script>
import { WS } from  '@/misc.js';

export default {
    name: "DeviceMount",
    props: { value: Object },
    data() {
        return {
            showModal: false,
            info: [],
            original: null,
            mount: null,
            din_options: [],
            fin_options: [],
            ain_options: [],
            sensor_options: [
                { text: 'Работа основного двигателя', value: 'ME' },
                { text: 'Работа основного двигателя N2', value: 'M2E' },
                { text: 'Работа основного двигателя N3', value: 'M3E' },
                { text: 'Работа дополнительного двигателя', value: 'SE' },
                { text: 'Работа дополнительного двигателя N2', value: 'S2E' },
                { text: 'Работа дополнительного двигателя N3', value: 'S3E' },
                { text: 'Работа дополнительного оборудования от основного двигателя', value: 'SM' },
                { text: 'Работа дополнительного оборудования от основного двигателя N2', value: 'S2M' },
                { text: 'Работа дополнительного оборудования от основного двигателя N3', value: 'S3M' },
                { text: 'Низкое питание', value: 'PWL' },
                { text: 'Сигнализация', value: 'ALR' },
                { text: 'Тревожная кнопка', value: 'SOS' },
                { text: 'Столкновение', value: 'SHK' },
                { text: 'Дверь', value: 'SD' },
                { text: 'Передняя дверь', value: 'FD' },
                { text: 'Задняя дверь', value: 'BD' },
                { text: 'Лебёдка', value: 'WN' },
                { text: 'Поднятие кузова', value: 'TB' },
                { text: 'Кондиционер', value: 'CN' },
                { text: 'Отопитель', value: 'HT' },
                { text: 'Миксер', value: 'MX' },
                { text: 'Крановая установка', value: 'CR' },
                { text: 'Электростанция', value: 'PS' },
                { text: 'Электростанция N2', value: 'P2S' },
                { text: 'Электростанция N3', value: 'P3S' },
                { text: 'Счетчик электроэнергии', value: 'PC' },
                { text: 'Счетчик электроэнергии N2', value: 'P2C' },
                { text: 'Счетчик электроэнергии N3', value: 'P3C' }
            ],
        }
    },
    async mounted() {
        try {
//            this.mounts = await WS.request('mounts', this.device_id);
            for (let i=1; i<=8; i++) {
                this.din_options.push({ text: 'DIN-'+i, value: ''+i });
                this.ain_options.push({ text: 'AIN-'+i, value: ''+i });
                this.fin_options.push({ text: 'FIN-'+i, value: ''+i });
            }
        } catch (err) {
            this.$bvModal.msgBoxOk(`mounts: ${err}`);
        }
    },
    methods: {
        open_mount: function (hw) {
            if (this.mount) {
                this.showModal = false;
            }
            this.mount = hw;
            if (this.mount.hardware_project == 'auto') {
                this.showModal = true;
            }
        },
        din_append: function () {
            this.mount.dins.push({ din: null, sensor: null })
        },
        din_remove: function (index) {
            this.mount.dins.splice(index,1)
        },
        ain_append: function () {
            this.mount.ains.push({ ain: null, sensor: null })
        },
        ain_remove: function (index) {
            this.mount.ains.splice(index,1)
        },
        fin_append: function () {
            this.mount.fins.push({ fin: null, sensor: null })
        },
        fin_remove: function (index) {
            this.mount.fins.splice(index,1)
        },
        savedata: async function () {
            let params = {}
            if (this.mount.hardware_project == 'auto') {
                params = { dins: this.mount.dins, ains: this.mount.ains, fins: this.mount.fins };
            }
            try {
                await WS.request('mount_update', { 'id': this.mount.id, 'data': params });
                this.showModal = false;
            } catch (err) {
                this.$bvModal.msgBoxOk(`mount_update: ${err}`);
            }
        }
    },
    filters: {
        formatDate: function (ts) {
            return ts ? (new Date(ts*1000)).toLocaleString('ru') : ''; 
        }
    },
}
</script>

<style>
  
</style>
