<template>
<b-container fluid>
    <b-row>
      <b-col>
        <b-form-group label="Владелец">
          <owner-selector v-model="formData.owner_id"></owner-selector>
        </b-form-group>
      </b-col>        
      <b-col>
        <b-form-group label="Идентификатор">
          <b-input type="text" v-model="formData.identifier" autofocus="true" required="true"> </b-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Телефоный номер">
          <b-input type="tel" required="true" v-model="formData.phone" v-facade="'+7 (###) ### ## ##'"></b-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Оператор">
          <b-form-select v-model="formData.ops" :options="opsOptions" required="true"> </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group label="Примечание">
      <b-form-textarea v-model="formData.notes" rows="3"> </b-form-textarea>
    </b-form-group>
    
    <b-form-group>
      <b-button squared variant="primary" @click="simSave" class="mx-1">Сохранить</b-button>
      <b-button squared variant="secondary" @click="$emit('editDone')" class="mx-1">Закрыть</b-button>
      <b-button v-if="simId" squared variant="danger" @click="simDelete" class="mx-1">Удалить</b-button>
    </b-form-group>
</b-container>
</template>

<script>
import OwnerSelector from "@/components/OwnerSelector.vue";
import { WS } from  '@/misc.js';

export default {
  name: "SimData",
  props: { simId: Number },
  components: { OwnerSelector },
  data() {
    return {
      formData: {
        owner_id: null,
        identifier: null,
        phone: null,
        ops: "4",
        notes: null
      },
      opsOptions: [
        { value: '1', text: 'Билайн' },
        { value: '2', text: 'СМАРТС' },
        { value: '3', text: 'Мегафон' },
        { value: '4', text: 'МТС' },
      ],
    }
  },

  async mounted() {
    try {
      if (this.simId) {
        let data = await WS.request('sim_data', this.simId);
        for (let key in this.formData) {
          this.formData[key] = data[key];
        }
      }
    } catch (err) {
      this.$bvModal.msgBoxOk(err);
    }
  },

  methods: {
    async simSave() {
      try {
        let result = await WS.request('sim_update', { id: this.simId, data: this.formData })
        this.$emit('editDone', result);
      } catch (err) {
        this.$bvModal.msgBoxOk(err);
      }
    },
    
    async simDelete() {
      try {
        let yesNo = await this.$bvModal.msgBoxConfirm('Вы уверены?', { okTitle:"Да", cancelTitle:"Нет" });
        if (yesNo) {
          await WS.request('sim_delete', this.simId);
          this.$emit('deleteDone', this.simId);
        }
      } catch (err) {
        this.$bvModal.msgBoxOk(err);
      }
    },

  }
}
</script>

<style scoped>

</style>
