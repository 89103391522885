<template>
<div id="app">
  <b-modal v-model="showlogin" title="Авторизация" ok-title="Войти"
           no-close-on-backdrop no-close-on-esc ok-only hide-header-close hide-backdrop
           @ok="login" > 
    <b-form-group label="Имя пользователя">
      <b-input type="text" v-model="username" v-on:keyup.enter="login" :autofocus="true" />
    </b-form-group>
    <b-form-group label="Пароль">
      <b-input type="password" v-model="password" v-on:keyup.enter="login"/>
    </b-form-group>
  </b-modal>
  
  <div v-if="authorized" >
    <b-button-group class="float-right">
      <b-button class="border"
                v-b-toggle.devices-sidebar
                v-b-tooltip.hover title="Онлайн">
        <b-icon icon="bicycle"></b-icon>
      </b-button>
      <b-button class="border"
                v-b-toggle.track-sidebar
                v-b-tooltip.hover title="Вспомнить всё">
        <b-icon icon="clock-history"></b-icon>
      </b-button>
      <b-button class="border" v-if="privileges.includes('root')"
                v-b-toggle.admin-collapse
                v-b-tooltip.hover title="Администрирование">
        <b-icon icon="tools"></b-icon>
      </b-button>
      <b-button class="border" @click="logout">
        <b-icon icon="box-arrow-right"></b-icon>
      </b-button>
    </b-button-group>

    <b-modal id="filter-modal" title="Фильтр" v-on:ok="filterSet" v-on:show="filterInit"
             ok-only ok-title="Установить">
      <b-form-group label="Фильтровать по ...">
        <b-form-radio inline v-model="filter.mode" value="owner">владельцу</b-form-radio>
        <b-form-radio inline v-model="filter.mode" value="devgroup">группе</b-form-radio>
      </b-form-group>
      <b-form-group v-if="filter.mode == 'owner'">
        <b-form-select v-model="filter.ownerId" :options="ownerList" multiple :select-size="7"
                       value-field="id" text-field="name"> </b-form-select>
      </b-form-group>
      <b-form-group v-if="filter.mode == 'devgroup'">
        <b-form-select v-model="filter.devGroup" :options="devGroupList" multiple :select-size="7"
                       value-field="id" text-field="name"> </b-form-select>
      </b-form-group>
    </b-modal>
    
    <!-- Онлайн -->
    <b-sidebar id="devices-sidebar" sidebar-class="border-right border-info onlinesidebar" no-header 
               v-model="onlineMode" @shown="onlineShown" @hidden="onlineHide">
      <template #default="{ hide }">
        <div>
          <div class="pb-1">
            <b-row align-v="center" align-h="between" class="px-0 mx-0">
              <b-col class="m-0 p-1">
                <p class="m-0 p-1 bg-secondary text-light">
                  <b-icon icon="bicycle"></b-icon> Онлайн
                </p>
              </b-col>
              <b-col cols="auto" class="m-1 p-0">
                  <b-button variant="primary" squared @click="hide" >
                    <b-icon icon="chevron-double-left"></b-icon>
                  </b-button>
              </b-col>
            </b-row>
          </div>
          
          <div class="px-1 pb-1">
            <!-- <b-form-group class="mb-1"> -->
            <!--   <b-form-input v-model="device_search" placeholder="поиск..." debounce="500"></b-form-input> -->
            <!-- </b-form-group> -->
            <b-button-group size="sm">
              <b-button variant="success" class="border" v-b-modal.filter-modal>
                <b-icon icon="filter" v-b-tooltip.hover.ds500 title="Фильтр"> </b-icon>
              </b-button>
              <b-button variant="secondary" :disabled="active_device === null" class="border"
                        @click="deviceTrack"
                        v-b-tooltip.hover.ds500 title="Вспомнить всё">
                <b-icon icon="clock-history"> </b-icon>
              </b-button>
              <b-button variant="info" :disabled="active_device === null" class="border"
                        v-b-modal.device-current-info-modal
                        v-b-tooltip.hover.ds500 title="информация о текущем состоянии">
                <b-icon icon="info"> </b-icon>
              </b-button>
              <b-button :variant="taggedOnly ? 'secondary' : 'light'" class="border"
                        @click="taggedOnlyClick" 
                        v-b-tooltip.hover.ds500 title="Только отмеченные">
                <b-icon icon="tag" v-if="!taggedOnly" ></b-icon>
                <b-icon icon="tag-fill" v-if="taggedOnly" ></b-icon>
              </b-button>              
            </b-button-group>
          </div>
        </div>

        <div class="onlinelist">
          <b-card no-body class="mb-1" v-for="grp in deviceGroups" :key="grp.id">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-form-checkbox switch v-model="grp.visible"
                               :aria-controls="`devlist-collapse-${grp.id}`"
                               :class="grp.visible ? null : 'collapsed'"
                               :aria-expanded="grp.visible ? 'true' : 'false'">
                {{grp.name}}
              </b-form-checkbox>
            </b-card-header>
            <b-collapse v-model="grp.visible" :id="`devlist-collapse-${grp.id}`" 
                        @hidden="hideDevGroup(grp.id)" @shown="showDevGroup(grp.id)">
                <b-list-group flush>
                  <b-list-group-item action class="p-1 flex-column align-items-start" 
                                     v-for="dev in deviceListFiltered[grp.id]" :key="dev.id"
                                     :active="active_device == dev.id"
                                     @click="set_active_device(dev.id)">
                    <div class="d-flex align-items-center">
                      <b-button v-if="dev.id in markers"
                                pressed variant="outline-info" class="mr-2 p-1"
                                @click="tag_device(dev.id)" >
                        <b-img-lazy width="48" height="48" v-bind:src="dev.image | iconpath"> </b-img-lazy>
                      </b-button>
                      <b-button v-else
                                variant="outline-light" class="mr-2 p-1"
                                @click="tag_device(dev.id)">
                        <b-img-lazy width="48" height="48" v-bind:src="dev.image | iconpath"> </b-img-lazy>
                      </b-button>
                      <div class="flex-fill">
                        <nobr> {{ dev.name }} </nobr>
                        <div class="float-right" >
                          <b-avatar v-if="dev.dut" text="ДУТ"
                                    variant="info" size="sm" rounded class="mr-1"
                                    v-b-tooltip.hover.ds500 title="Датчик Уровня Топлива"> </b-avatar>
                          <b-avatar v-if="dev.photo" icon="camera"
                                    variant="info" size="sm" rounded class="mr-1"
                                    v-b-tooltip.hover.ds500 title="Фото"></b-avatar>
                          <b-avatar v-for="(sens,i) in dev.sensors" :key="i" :text="sens"
                                    variant="danger" size="sm" rounded class="mr-1"></b-avatar>
                          <b-badge v-if="dev.lastnav" variant="info" class="mr-1">
                            {{ dev.lastnav|datetime }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
            </b-collapse>
          </b-card>
        </div>
        
      </template>
    </b-sidebar>

    <div class="float-left" v-if="markersVisible">
      <div class="d-flex align-items-center p-1" v-for="(d,idx) in markers" :key="idx">
        <b-avatar class="mr-3" button square size="48px" variant="transparent"
                  :src="d.icon.options.iconUrl"
                  @click="set_active_device(idx)">
        </b-avatar>
        <span>
          <h4 class="shadowtext">{{ d.speed }} км/ч ({{ d.lastnav }})</h4>
        </span>
      </div>
    </div>

    <!-- треки -->
    <b-sidebar id="track-sidebar" sidebar-class="border-right border-info tracksidebar" no-header 
               v-model="trackMode" @shown="trackShow" @hidden="trackHide">
      <template #default="{ hide }">
        <div class="pb-1">
          <b-row align-v="center" align-h="between" class="px-0 mx-0">
            <b-col class="m-0 p-1">
              <p class="m-0 p-1 bg-secondary text-light">
                <b-icon icon="clock-history"></b-icon> Вспомнить всё
              </p>
            </b-col>
            <b-col cols="auto" class="m-1 p-0">
              <b-button variant="primary" squared @click="hide" >
                <b-icon icon="chevron-double-left"></b-icon>
              </b-button>
            </b-col>
          </b-row>
          
          <div class="px-1 pb-1">
            <b-form-group class="mb-1">
              <owner-selector v-model="trackOwnerId" v-on:input="trackDevices"> </owner-selector>
            </b-form-group>
            <!-- <b-form-group class="mb-1"> -->
            <!--   <b-form-input v-model="device_search" placeholder="поиск..." debounce="500"></b-form-input> -->
            <!-- </b-form-group> -->
          </div>
        </div>
        
        <div class="p-1">
          <b-dropdown block split split-variant="outline-secondary">
            <template #button-content>
              <div class="d-flex" v-if="trackDevice.id">
                <b-img width="48" height="48" v-bind:src="trackDevice.image | iconpath"> </b-img>
                <div class="mx-2 align-self-center"> {{ trackDevice.name }} </div>
              </div>
              <div v-else> Пусто </div>
            </template>
            <div class="selector-droplist" >
              <b-dropdown-item v-for="dev in trackDeviceList" :key="dev.id"
                               v-on:click="trackDevice = dev">
                <div class="d-flex">
                  <b-img width="48" height="48" v-bind:src="dev.image | iconpath"> </b-img>
                  <div class="mx-2 align-self-center"> {{ dev.name }} </div>
                </div>
              </b-dropdown-item>
            </div>
          </b-dropdown>

          <div class="my-2">
            <b-form-group label="Начало">
              <b-input-group>
                <b-form-datepicker v-model="trackStartDate" locale="ru" hide-header size="sm" placeholder="no date"
                                   :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }">
                </b-form-datepicker>
                <b-form-timepicker v-model="trackStartTime" locale="ru" size="sm" placeholder="no time"
                                   no-close-button hide-header minutes-step="10">
                </b-form-timepicker>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Окончание" >
              <b-input-group >
                <b-form-datepicker v-model="trackFinishDate" locale="ru" hide-header size="sm" placeholder="no date"
                                   :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }">
                </b-form-datepicker>
                <b-form-timepicker v-model="trackFinishTime" locale="ru" size="sm" placeholder="no time"
                                   no-close-button hide-header minutes-step="10">
                </b-form-timepicker>
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <b-input-group prepend="Цвет">
                <b-form-input type="color" v-model="trackColor"></b-form-input>
              </b-input-group>            
            </b-form-group>

            <b-button-group>
              <b-button @click="loadTrack"> Загрузить </b-button>
              <b-button @click="clearTrack"> Очистить </b-button>
            </b-button-group>
            <b-form-checkbox v-model="chartVisible" switch> График </b-form-checkbox>
            <b-form-checkbox v-model="photoVisible" switch> Фото </b-form-checkbox>
          </div>
        </div>
      </template>
    </b-sidebar>
    
    <b-collapse v-model="chartVisible" id="collapse-1" class="fixed-bottom mychart">
      <b-card no-body >
        <apexchart type="area" height="200" :options="chartOptions" :series="chartSeries"></apexchart>
      </b-card>
    </b-collapse>
    
    <b-modal id="device-current-info-modal" size="lg" title="Инфо" hide-header ok-only ok-title="Закрыть">
      <current-info device-id="active_device"></current-info>
    </b-modal>

    <!-- Админка -->
    <b-sidebar id="admin-collapse" width="100%" no-header>
      <template #default="{ hide }">
        <admin-view @hide="hide"></admin-view>
      </template>      
    </b-sidebar>

    <b-modal id="photo-modal" size="xl" scrollable hide-footer>
      <b-row>
        <b-col cols="4"> Камера:</b-col>
        <b-col>{{photoData.cam}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4"> Время: </b-col>
        <b-col> {{photoData.time_stamp|timestamp}} </b-col>
      </b-row>
      <b-row>
        <b-col cols="4"> Событие: </b-col>
        <b-col> {{photoData.origin}} </b-col>
      </b-row>
      <b-img-lazy fluid :src="getPhotoURL(photoData.id)"> </b-img-lazy>
    </b-modal>
      

    <l-map id="map" ref="map" class="mapcontainer"
           :zoom.sync="zoom"
           :options="mapOptions"
           :center="center"
           @ready="onMapReady()">
      <l-control-zoom position="bottomright"></l-control-zoom>      
      <l-control-layers ref="control_layers" position="bottomright"></l-control-layers>
      <l-tile-layer v-for="tileProvider in tileProviders"
                    :key="tileProvider.name"
                    :name="tileProvider.name"
                    :visible="tileProvider.visible"
                    :url="tileProvider.url"
                    :attribution="tileProvider.attribution"
                    :options="tileProvider.options"
                    layer-type="base"/>
      <l-marker v-for="(marker,id) in markers"
                :visible="markersVisible"
                :key="`marker${id}`"
                :lat-lng="marker.position"
                :icon="marker.icon"
                @click="onMarkerClick(id)">
        <l-tooltip :content="marker.tooltip" :options="tooltipOptions"/>
      </l-marker>
      <l-polyline v-for="(tail,id) in tails"
                  :visible="markersVisible"
                  :key="`tail${id}`"
                  :lat-lngs="tail.latlngs"
                  :color="tail.color"
                  ref="tails">
        <l-tooltip :content="tail.color"/>
      </l-polyline>
      <l-polyline v-if="trackData"
                  :visible="trackVisible"
                  :lat-lngs="trackData.latlngs"
                  :color="trackData.color"
                  @click="showTrackInfo($event)">
      </l-polyline>
      <l-marker v-if="trackMarker"
                :visible="trackVisible"
                :lat-lng="trackMarker.position"
                :icon="trackMarker.icon">
        <l-tooltip :content="trackMarker.tooltip" :options="tooltipOptions"/>
      </l-marker>
      <l-marker v-for="mrk in photoMarkers"
                :key="mrk.id"
                :lat-lng="mrk.position"
                :visible="photoVisible">
        <l-icon icon-url="/images/emblem-photos.png"> </l-icon>
        <l-popup>
          <b-button @click="showPhoto(mrk.id)" class="m-0 p-0">
            <b-img-lazy width="250" :src="getPhotoURL(mrk.id)"> </b-img-lazy>
          </b-button>
        </l-popup>
      </l-marker>
    </l-map>
  </div>

</div>
</template>

<script>
import Vue from 'vue';
import { Icon } from "leaflet";
// import { LineUtil, Icon } from "leaflet";
import GeometryUtil from "leaflet-geometryutil";
// import L from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip, LIcon, LPopup, LPolyline,
         LControlLayers, LControlZoom } from 'vue2-leaflet';
// import 'leaflet/dist/leaflet.css';
// import Wkt from 'wicket/wicket-leaflet';

// import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faGasPump, faPlug } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import moment from 'moment';
import VueSSE from 'vue-sse';
import VueApexCharts from 'vue-apexcharts';

import { bingLayer } from '@/plugins/Bing.js';
import NoSleep from 'nosleep.js';
import OwnerSelector from '@/components/OwnerSelector.vue';
import AdminView from '@/components/AdminView.vue';
import CurrentInfo from '@/components/CurrentInfo.vue';

import { WS, backend_URI } from '@/misc.js';

Vue.use(VueSSE);
Vue.use(VueApexCharts);
moment.locale('ru');

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

var noSleep = new NoSleep();

document.addEventListener('click', function enableNoSleep() {
    document.removeEventListener('click', enableNoSleep, false);
    noSleep.enable();
}, false);

const tileProviders = [
    {
        name: "OpenStreetMap",
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        visible: false,
        url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
        options: {
            maxZoom:20
        }
    },
    {
        name: "2Gis",
        visible: true,
        url: 'https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}',
        options: {
            maxNativeZoom: 18,
            maxZoom: 18,
        }
    },
];

export default {
    name: 'app',
    metaInfo: {
        title: 'Monitoring',
        htmlAttrs: { lang: 'ru' },
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' }
        ]
    },
    components: {
        LMap, LTileLayer, LMarker, LTooltip, LPolyline, LControlLayers, LControlZoom, LIcon, LPopup, 
        // FontAwesomeIcon,
        apexchart: VueApexCharts,
        OwnerSelector,
        AdminView,
        CurrentInfo,
    },
    data() {
        return {
            username: null,
            password: null,
            privileges: [],
            apikey: null,
            showlogin: true,
            authorized: false,
            authdata: null,
            ownerList: [],
            devGroupList: [],
            ownerId: [],
            devGroup: [],
            filter: { mode: null, ownerId: [], devGroup: [] },

            center: [46.374735, 48.060413],
            zoom: 19,
            mapOptions: {
                zoomControl: false,
                attributionControl: false,
                // zoomSnap: 0.5
            },
            tileProviders: tileProviders,
            tooltipOptions: { permanent: true, direction: 'top', opacity: 0.7, offset: [0,-13] },
            
            markers: {},
            tails: {},
            taggedOnly: false,
            
            trackData: null,
            trackMarker: null,
            dutData: [],
            photoList: [],
            photoData: {},
            photoMarkers: [],
            photoVisible: false,
            
            // trackSidebar: false,
            markersVisible: true,
            onlineMode: false,
            trackMode: false,
            trackVisible: false,
            trackOwnerId: null,
            trackDeviceList: [],
            trackDevice: {},
            trackStartDate: null,
            trackStartTime: null,
            trackFinishDate: null,
            trackFinishTime: null,
            trackColor: null,
            
            active_device: null,
            devices: {},
            deviceGroups: {},
            deviceList: {},
            device_search: '',

            chartVisible: false,
            chartSeries: [],
            chartOptions: {
                chart: {
                    // type: 'area',
                    // height: 300,
                    events: {
                        click: (event, chartContext, config) => {
                            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                            if (config.seriesIndex == 0) {
                                this.showTrackMarkerByNav(config.dataPointIndex);
                            } else if (config.seriesIndex == 1) {
                                this.showTrackMarkerByDut(config.dataPointIndex);
                            }
                        }
                    }
                },
                stroke: {
                    curve: 'straight',
                    width: 2
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: { enabled: false },
                    labels: { datetimeUTC: false, },
                },
                yaxis: [
                    { title: { text: "Скорость" } },
                    { title: { text: "ДУТ" }, opposite: true },
                ],
                tooltip: {
                    x: { format: 'dd.MM.yy HH:mm' },
                },
            },
            
        }
    },
    
    async mounted() {
        this.sseClient = this.$sse.create({
            url: backend_URI + '/events',
            format: 'json',
            withCredentials: true,
            polyfill: true,
        });
        // Catch any errors (ie. lost connections, etc.)
        this.sseClient.on('error', (e) => {
            console.error('lost connection or failed to parse!', e);
        });
        this.sseClient.on('online', this.onLine);
        this.sseClient.on('refresh', this.onRefresh);
        this.sseClient.on('exit', this.logout);
    },
    
    beforeDestroy() {
        this.sseClient.disconnect();
    },
    
    computed: {
        datanotok() {
            return Boolean(!this.username || !this.password)
        },
        deviceListFiltered() {
            if (this.taggedOnly) {
                return Object.fromEntries(
                    Object.entries(this.deviceList).map(([key, value]) => [key, value.filter(item => item.id in this.markers)])
                );
            } else {
                return this.deviceList;
            }
        }
    },
    
    watch: {
        // async ownerId() {
        //     await this.refreshDevices();
        // },
        // async trackOwnerId() {
        //     await this.trackDevices();
        // },
    },
    
    provide: function () {
        return {
            getMap: this.getMap,
            setHideAll: this.setHideAll,
            getPrivileges: this.getPrivileges,
        }
    },
    
    methods: {
        onMapReady() {
            let bing = bingLayer('Ait048JoooLtii6I13f4CODf6CeOIZeh0ZzJGhP7QaQM5PXqbRo_DY0HWZ1O_acA',
                                 { imagerySet: 'AerialWithLabelsOnDemand' });
            this.$refs.control_layers.mapObject.addBaseLayer(bing,"Bing");
        },
        
        getPrivileges() {
            return this.privileges;
        },
        
        getMap() {
            return this.$refs.map.mapObject;
        },
        
        init_events() {
            this.sseClient.connect()
                .then(sse => {
                    console.log('We\'re connected!', sse);
                })
                .catch((err) => {
                    // When this error is caught, it means the initial connection to the
                    // events server failed.  No automatic attempts to reconnect will be made.
                    console.error('Failed to connect to server', err);
                });
        },
        
        async logout() {
            this.privileges = [];
            this.apikey = null;
            this.authorized = false;
            this.showlogin = true;
            this.ownerId = [];
            this.ownerList = [];
            this.devGroupList = [];
            this.devGroup = [];
            this.devices = {};
            this.deviceList = {};
            this.deviceGroups = {};
            this.tails = {};
            this.markers = {};
            this.trackOwnerId = null;
            this.trackDeviceList = [];
            this.trackDevice = {};
            this.trackData = null;
            this.trackMarker = null;
            this.dutData = [];
            this.photoList = [];
            this.photoMarkers = [];
            this.photoData = {};
            this.chartSeries = [];
            try {
                this.sseClient.disconnect();
                await WS.logout();
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },

        async login(bvModalEvt) {
            bvModalEvt.preventDefault();
            try {
                let result = await WS.login(this.username, this.password)
                this.apikey = result.apikey;
                this.init_events();
                let userInfo = await WS.request('user_info');
                this.privileges = userInfo.privileges;
                this.authorized = true;
                this.showlogin = false;
                this.active_device = null;
                if (userInfo.owner_id != null) {
                    this.ownerId = [ userInfo.owner_id ];
                }
                this.trackOwnerId = userInfo.owner_id;
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
                await this.logout();
            }
        },

        async refreshDevices() {
            try {
                let devlist = await WS.request('devices', { owners: this.ownerId, devgroups: this.devGroup });
                this.deviceGroups = {};
                this.deviceList = {};
                this.devices = {};
                devlist.forEach((item) => {
                    let grpkey = item.group_id;
                    let grpname = item.group_name;
                    if (this.deviceList[grpkey] === undefined) {
                        Vue.set(this.deviceGroups, grpkey, { id: grpkey, name: grpname, visible: true });
                        // this.deviceGroups[grpkey] = ;
                        Vue.set(this.deviceList, grpkey, []);
                    }
                    this.deviceList[grpkey].push(item);
                    this.devices[item.id] = item;
                });
                // this.devices = Object.fromEntries(this.deviceList.map(item => [item.id,item]));
                if (this.devices[this.active_device] === undefined) {
                    this.active_device = null;
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`refreshDevices:${err}`);
            }
        },

        async minimizeDevices() {
            await WS.request('devices_minimize');
        },

        async maximizeDevices() {
            await WS.request('devices_maximize');
        },

        async taggedOnlyClick() {
            this.taggedOnly = ! this.taggedOnly;
            let devs = Object.keys(this.devices).map(item => Number(item));
            if (this.taggedOnly) {
                await WS.request('devices_hide', { devices: devs });
            } else {
                await WS.request('devices_show', { devices: devs });
            }
        },

        async hideDevGroup(grpkey) {
            let devs = this.deviceList[grpkey].map(item => item.id);
            await WS.request('devices_hide', { devices: devs });
        },

        async showDevGroup(grpkey) {
            let devs = this.deviceList[grpkey].map(item => item.id);
            await WS.request('devices_show', { devices: devs });
        },

        onRefresh(data) {
            let dev = this.devices[data.id];
            if (dev) {
                for (let key of ['sensors','speed','lastnav']) {
                    if (key in data) {
                        dev[key] = data[key];
                    }
                }
            }
        },

        async onLine(data) {
            let dev = this.devices[data.id];
            if (!dev) { return }
            if (data.latitude) {
                let marker = this.markers[data.id];
                let tm = moment.unix(dev.lastnav);
                let lastnav = (tm.isAfter(moment().startOf('date'))) ? tm.format("HH:mm") : tm.format("DD.MM.YYYY");
                if (marker) {
                    marker.position = [data.latitude, data.longitude];
                    marker.tooltip = `${dev.name}`;
                    marker.speed = dev.speed;
                    marker.lastnav = lastnav;
                } else {
                    Vue.set(this.markers, data.id, {
                        position: [data.latitude, data.longitude],
                        icon: new Icon({
                            iconUrl: `/icons/${dev.image}.png`,
                            iconSize: [32, 32]
                        }),
                        tooltip: `${dev.name}`,
                        speed: dev.speed,
                        lastnav: lastnav,
                    });
                }
                let tail = this.tails[data.id];
                if (tail) {
                    tail.latlngs.push([data.latitude, data.longitude]);
                    if (tail.latlngs.length > 20) {
                        tail.latlngs.shift();
                    }
                } else {
                    Vue.set(this.tails, data.id, {
                        latlngs: [[data.latitude, data.longitude]],
                        color: 'green',
                    })
                }
                if (data.id == this.active_device && this.markersVisible) {
                    if (marker) {
                        this.$refs.map.mapObject.panInside(marker.position, { padding: [100,100] });
                    } else {
                        this.$refs.map.mapObject.panTo([data.latitude, data.longitude]);
                    }
                }
                
            } else if (!('latitude' in data)) {
                Vue.delete(this.markers,data.id);
                Vue.delete(this.tails,data.id);
                for (let key of ['place','dut_value']) {
                    if (key in dev) {
                        Vue.delete(dev,key);
                    }
                }
            }
            for (let key of ['sensors','speed','lastnav','place','dut_value']) {
                if (key in data) {
                    dev[key] = data[key];
                }
            }
        },

        onMarkerClick(device_id) {
            this.set_active_device(device_id);
        },
        
        set_active_device(device_id) {
            console.log('set_active_device');
            if (this.markersVisible) {
                let marker = this.markers[device_id];
                if (marker) {
                    this.$refs.map.mapObject.panTo(marker.position);
                }
            }
            this.active_device = device_id;
        },

        async tag_device(device_id) {
            await WS.request('device_tag', device_id);
        },

        async onlineShown() {
            this.trackMode = false;
            this.trackVisible = false;
            this.chartVisible = false;
            this.photoVisible = false;
            this.markersVisible = true;
            // await this.refreshDevices();
            await this.maximizeDevices();
        },

        async onlineHide() {
            await this.minimizeDevices();
        },

        async trackShow() {
            this.onlineMode = false;
            this.markersVisible = false;
            this.trackVisible = true;
            if (this.trackMarker) {
                this.$refs.map.mapObject.panTo(this.trackMarker.position);
            }
        },

        async trackHide() {
        },

        async trackDevices() {
            this.trackDeviceList = await WS.request('track_devices', this.trackOwnerId);
            this.trackDevice = this.trackDeviceList.find(item => item.id == this.trackDevice.id);
            if (this.trackDevice === undefined) {
                this.trackDevice = {};
            }
        },

        clearTrack() {
            this.trackData = null;
            this.trackMarker = null;
            this.dutData = [];
            this.photoList = [];
            this.photoMarkers = [];
            this.chartSeries = [];
        },

        async loadTrack() {
            try {
                this.clearTrack();
                let data = await WS.request('track',
                                            {
                                                device_id: this.trackDevice.id,
                                                start_date: this.trackStartDate,
                                                start_time: this.trackStartTime,
                                                finish_date: this.trackFinishDate,
                                                finish_time: this.trackFinishTime
                                            });
                if (data.length > 0) {
                    let dutseries = [];
                    let latlngs = [];
                    let speedseries = [];
                    this.dutData = await WS.request('track_dut',
                                               {
                                                   device_id: this.trackDevice.id,
                                                   start_date: this.trackStartDate,
                                                   start_time: this.trackStartTime,
                                                   finish_date: this.trackFinishDate,
                                                   finish_time: this.trackFinishTime
                                               });
                    if (this.dutData.length > 0) {
                        dutseries = this.dutData.map(item => ([item.time_stamp*1000, item.value]));
                    }
                    data.forEach((item) => {
                        latlngs.push([item.latitude, item.longitude]);
                        speedseries.push([item.time_stamp*1000, item.speed]);
                    });
                    this.trackData = {
                        data: data,
                        latlngs: latlngs,
                        color: this.trackColor
                    };
                    this.trackMarker = {
                        position: latlngs[0],
                        icon: new Icon({
                            iconUrl: `/icons/${this.trackDevice.image}.png`,
                            iconSize: [32, 32]
                        }),
                        tooltip: `${this.trackDevice.name}`,
                    };
                    this.$refs.map.mapObject.panTo(this.trackMarker.position);
                    this.chartSeries.push({name: 'Скорость', data: speedseries})
                    if (dutseries.length > 0) {
                        this.chartSeries.push({name: 'ДУТ', data: dutseries})
                    }
                } else {
                    this.$bvModal.msgBoxOk('Нет данных');
                }
                
                this.photoList = await WS.request('track_photo',
                                                  {
                                                      device_id: this.trackDevice.id,
                                                      start_date: this.trackStartDate,
                                                      start_time: this.trackStartTime,
                                                      finish_date: this.trackFinishDate,
                                                      finish_time: this.trackFinishTime
                                                  });
                this.photoMarkers = [];
                this.photoList.forEach((item) => {
                    this.photoMarkers.push({
                        id: item.id,
                        position: item.navpoint
                    });
                    // photoseries.push([item.time_stamp*1000, item.id]);
                });
                
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },

        showTrackInfo(e) {
            const latlngs = this.trackData.latlngs;
            let segments = [];
            for (let i = 0; i < latlngs.length - 1; i++) {
                const pointToLineDistance = GeometryUtil.distanceSegment(
                    this.$refs.map.mapObject,
                    e.latlng,
                    latlngs[i],
                    latlngs[i + 1]
                );
                segments.push({
                    index: i,
                    pointToLineDistance,
                    segment: [latlngs[i], latlngs[i + 1]]
                });
            }
            segments.sort((a, b) =>
                a.pointToLineDistance < b.pointToLineDistance ? -1 : 1
            );
            const shortestSegment = segments[0];
            const p1Dist = GeometryUtil.distance(this.$refs.map.mapObject, e.latlng, shortestSegment.segment[0]); 
            const p2Dist = GeometryUtil.distance(this.$refs.map.mapObject, e.latlng, shortestSegment.segment[1]);
            
            this.showTrackMarkerByNav(p1Dist < p2Dist ? shortestSegment.index : shortestSegment.index + 1);
        },

        showTrackMarkerByNav(index) {
            let data = this.trackData.data[index];
            let dut = {};
            let dutindex = this.dutData.findIndex(item => item.time_stamp > data.time_stamp);
            if (dutindex > 0) {
                dut = this.dutData[dutindex-1];
            }
            this.trackMarker.position = [ data.latitude, data.longitude ];
            this.trackMarker.tooltip = `${this.trackDevice.name}` + '<br />' + 
                `Время: ${moment.unix(data.time_stamp).format('lll')}` + '<br />' +
                `Топливо: ${dut.value}` + '<br />' +
                `Скорость: ${data.speed}` + '<br />' +
                `Сенсоры: ${data.sensors}`;
            this.$refs.map.mapObject.panTo(this.trackMarker.position);
        },

        showTrackMarkerByDut(index) {
            let dut = this.dutData[index];
            let data = {};
            let dataindex = this.trackData.data.findIndex(item => item.time_stamp > dut.time_stamp);
            if (dataindex > 0) {
                data = this.trackData.data[dataindex-1];
            }
            this.trackMarker.position = [ data.latitude, data.longitude ];
            this.trackMarker.tooltip = `${this.trackDevice.name}` + '<br />' + 
                `Время: ${moment.unix(dut.time_stamp).format('lll')}` + '<br />' +
                `Топливо: ${dut.value}` + '<br />' +
                `Скорость: ${data.speed}` + '<br />' +
                `Сенсоры: ${data.sensors}`;
            this.$refs.map.mapObject.panTo(this.trackMarker.position);
        },

        async deviceTrack() {
            this.trackDevice = this.devices[this.active_device];
            this.trackOwnerId = this.trackDevice.owner_id;
            await this.trackDevices();
            this.trackMode = true;
        },

        async filterInit() {
            this.filter.ownerId = this.ownerId;
            this.filter.devGroup = this.devGroup;
            try {
                this.ownerList = await WS.request('owners');
                this.devGroupList = await WS.request('devgroups');
            } catch (err) {
                this.$bvModal.msgBoxOk(`filterInit:${err}`);
            }
        },

        async filterSet() {
            if (this.filter.mode == 'owner') {
                this.ownerId = this.filter.ownerId;
                this.devGroup = [];
            } else {
                this.ownerId = [];
                this.devGroup = this.filter.devGroup;
            }
            await this.refreshDevices();
        },

        getPhotoURL(photo_id) {
            return `${backend_URI}/photo/${photo_id}?apikey=${this.apikey}`;
        },

        async showPhoto(photoId) {
            this.photoData = this.photoList.find(item => item.id == photoId);
            this.$bvModal.show('photo-modal');
        },
    },

    filters: {
        timestamp(ts) {
            // return moment.unix(ts).utcOffset(this.userInfo.utcoffset).calendar();
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },

        datetime(ts) {
            let tm = moment.unix(ts);
            if (tm.isAfter(moment().startOf('date'))) {
                return tm.format("HH:mm");
            } else {
                return tm.format("DD.MM.YYYY");
            }
        },

        iconpath(image) {
            return '/icons/'+image+'.png';
        },

        imagepath(image) {
            return '/images/'+image+'.png';
        },
    }
}
</script>

<style>
@import "~leaflet/dist/leaflet.css";

.mapcontainer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -100;
}
.onlinesidebar {
    width: 100%;
}
.tracksidebar {
    width: 100%;
}
@media (min-width: 768px) {
    .onlinesidebar {
        width: 400px;
    }
    .tracksidebar {
        width: 500px;
    }
}
@media (min-width: 1200px) {
    .onlinesidebar {
        width: 500px;
    }
}
.selector-droplist {
    overflow-y: scroll;
    max-height: 500px;
}
.mychart {
    opacity: 0.70;
    width: calc(100% - 50px);
}
.onlinelist {
    overflow-y: scroll;
    max-height: 85vh;
}
.shadowtext {
    text-shadow: 2px 2px 3px black; /* Параметры тени */
}
</style>
