<template>
<b-dropdown v-bind:text="valueText" variant="secondary">
  <b-dropdown-form>
    <b-form-input v-model="search" placeholder="поиск..." ></b-form-input>
  </b-dropdown-form>
  <div class="selector-droplist" >
    <b-dropdown-item v-if="emptyvalue" v-on:click="$emit('input', null)">
      <em>Пусто</em>
    </b-dropdown-item>
    <b-dropdown-divider v-if="emptyvalue" ></b-dropdown-divider>
    <b-dropdown-item v-for="opt in filteredOptions"
                     v-bind:key="opt.id"
                     v-bind:active="opt.id === value"
                     v-on:click="$emit('input', opt.id)">
      {{opt.name}}
    </b-dropdown-item>
  </div>
</b-dropdown>
</template>

<script>
import { WS } from  '@/misc.js';

export default {
    name: "SimSelector",
    props: {
        value: Number,
        emptyvalue: Boolean,
        hardwareId: Number,
    },
    data: () => ({
        options: [],
        search: '',
    }),
    computed: {
        filteredOptions() {
            return this.options.filter(item => {
                return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            });
        },
        valueText() {
            let opt = this.options.find(item => item.id == this.value);
            return opt ? opt.name : 'Неизвестно';
        }
    },
    async mounted() {
        await this.refreshOptions();
    },
    methods: {
        async refreshOptions() {
            try {
                this.options = await WS.request('lssim', this.hardwareId )
            } catch (err) {
                this.$bvModal.msgBoxOk(err);
            }
        },
    },
}
</script>

<style scoped>

</style>
