<template>
<b-container fluid>
  <b-row>
    <b-col>
      <b-form-group label="Наименование" label-for="owner-name">
        <b-input id="owner-name" type="text" v-model="formData.name"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-checkbox v-model="formData.state" name="check-button" switch> Действующий </b-form-checkbox>
    </b-col>
  </b-row>
  <b-form-group label="Полное наименование" label-for="owner-fullname">
    <b-input id="owner-fullname" type="text" v-model="formData.name_full"></b-input>
  </b-form-group>
  <b-form-group label="Адрес" label-for="owner-address">
    <b-input id="owner-address" type="text" v-model="formData.address"></b-input>
  </b-form-group>
  <b-row>
    <b-col>
      <b-form-group label="Тип владельца">
        <b-form-select v-model="formData.type">
          <b-form-select-option value="U">Юридическое лицо</b-form-select-option>
          <b-form-select-option value="F">Физическое лицо</b-form-select-option>
          <b-form-select-option value="P">Предприниматель</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Вид клиента">
        <b-form-select v-model="formData.kind">
          <b-form-select-option value="regular">Обычный</b-form-select-option>
          <b-form-select-option value="shuttle">Маршрутки</b-form-select-option>
          <b-form-select-option value="private">Частник</b-form-select-option>
          <b-form-select-option value="ship">Флотский</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-form-group label="Сотовый телефон">
        <b-input type="tel" v-model="formData.phone" v-facade="'+7 (###) ### ## ##'"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Другие телефоны">
        <b-input type="text" v-model="formData.phones"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="E-Mail">
        <b-input type="email" v-model="formData.email"></b-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-form-group label="ИНН">
        <b-input type="text" v-model="formData.inn"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="КПП">
        <b-input type="text" v-model="formData.kpp"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="ОГРН">
        <b-input type="text" v-model="formData.ogrn"></b-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-form-group label="Банковские реквизиты">
    <b-input type="text" v-model="formData.bank_data"></b-input>
  </b-form-group>
  <b-row>
    <b-col>
      <b-form-group label="Тарифный план">
        <b-form-select v-model="formData.tariff_plan">
          <b-form-select-option value="max">Максимальный</b-form-select-option>
          <b-form-select-option value="shuttle+gsm">Маршрут+GSM</b-form-select-option>
          <b-form-select-option value="shuttle">Маршрут</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Абон.плата (руб)">
        <b-input type="number" v-model="formData.tariff"></b-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-form-group label="Дата договора">
        <b-input type="text" v-model="formData.contract_date"></b-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Номер договора">
        <b-input type="text" v-model="formData.contract_number"></b-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-form-group label="Волшебное слово">
    <b-input type="text" v-model="formData.passphrase"></b-input>
  </b-form-group>
  <b-form-group label="Примечание" label-for="owner-notes">
    <b-form-textarea id="owner-notes" v-model="formData.notes" rows="3"> </b-form-textarea>
  </b-form-group>
 
  <b-button squared variant="primary" @click="saveOwner" class="mr-2">Сохранить</b-button>
  <b-button squared variant="secondary" @click="$emit('editDone')" class="mr-2">Закрыть</b-button>
  <b-button v-if="ownerId" squared variant="primary" @click="deleteOwner" class="mr-2">Удалить</b-button>
</b-container>
</template>

<script>
import { WS } from  '@/misc.js';

export default {
  name: "OwnerData",
  props: { ownerId: Number },
  data() {
    return {
      formData: {},
      classes: []
    }
  },
  async mounted() {
    try {
      if (this.ownerId) {
        this.formData = await WS.request('owner_data', this.ownerId);
      }
    } catch (err) {
      this.$bvModal.msgBoxOk(`ownerData: ${err}`);
    }
  },
  methods: {
    async saveOwner() {
      try {
        let result = await WS.request('owner_update', { id:this.ownerId, data:this.formData })
        this.$emit('editDone', result);
      } catch (err) {
        this.$bvModal.msgBoxOk(err);
      }
    },
    async deleteOwner() {
      try {
        let yesNo = await this.$bvModal.msgBoxConfirm('Вы уверены?', { okTitle:"Да", cancelTitle:"Нет" });
        if (yesNo) {
          await WS.request('owner_delete', this.ownerId);
          this.$emit('deleteDone', this.ownerId);
        }
      } catch (err) {
        this.$bvModal.msgBoxOk(err);
      }
    },
  }
}
</script>

<style scoped>

</style>
