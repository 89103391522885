<template>
<b-container fluid class="pt-3">
  <b-row>
    <b-col md="2">
      <b-form-group label="Идентификатор" label-size="sm">
        <b-form-input v-model="filter.identifier" @update="refresh" type="search" size="sm" debounce="1000"> </b-form-input>
      </b-form-group>
      <b-form-group label="Номер прибора" label-size="sm">
        <b-form-input v-model="filter.number" @update="refresh" type="search" size="sm" debounce="1000"> </b-form-input>
      </b-form-group>
      <b-form-group label="Объект" label-size="sm">
        <b-form-input v-model="filter.device" @update="refresh" type="search" size="sm" debounce="1000"> </b-form-input>
      </b-form-group>
      <b-form-group label="Владелец объекта" label-size="sm">
        <owner-selector v-model="filter.device_owner_id" @input="refresh" emptyvalue> </owner-selector>
      </b-form-group>
      <b-form-group label="Владелец SIM" label-size="sm">
        <owner-selector v-model="filter.owner_id" @input="refresh" emptyvalue> </owner-selector>
      </b-form-group>
      
      <b-button variant="outline-primary" squared size="sm" @click="onNewClick"> Добавить </b-button>
    </b-col>
    
    <b-col>
      <b-table-lite striped small hover light responsive :fields="fields" :items="items" @row-clicked="onRowClick">
        <template v-slot:row-details="row">
          <b-card>
            <sim-data :simId="row.item.id" @editDone="onEditDone(row,$event)" @deleteDone="onDeleteDone(row)"> </sim-data>
          </b-card>
        </template>
      </b-table-lite>
      
      <b-card v-if="appendNew" ref="newRowCard">
        <sim-data @editDone="onEditDone(null,$event)"> </sim-data>
      </b-card>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import Vue from 'vue';
import OwnerSelector from "@/components/OwnerSelector.vue";
import SimData from "@/components/SimData.vue";
import { WS } from  '@/misc.js';

export default {
  name: "SimList",
  components: { OwnerSelector,SimData },

  data() {
    return {
      items: [],
      fields: [
        { key: 'identifier', label: 'Идентификатор' },
        { key: 'phone', label: 'Номер' },
        { key: 'op', label: 'Оператор' },
        { key: 'hardware', label: 'Прибор' },
        { key: 'device_name', label: 'Объект' },
        { key: 'device_owner', label: 'Владелец объекта' },
        { key: 'owner', label: 'Владелец SIM' },
      ],
      appendNew: false,
      filter: { number: null, identifier: null, device: null, device_owner_id: null, owner_id: null },
    }
  },

  methods: {
    async refresh() {
      try {
        this.items = await WS.request('sim_list', { filter: this.filter });
        this.items.forEach(item => {
          Vue.set(item, '_showDetails', false);
        });
      } catch (err) {
        alert(err);
      }
    },
    
    async onEditDone(row, simId) {
      if (simId) {
        try {
          let data = await WS.request('sim_list_one', simId);
          Vue.set(data, '_showDetails', false);
          let index = this.items.findIndex(item => item.id == simId);
          if (index >= 0) {
            this.items.splice(index, 1, data);
          } else {
            this.items.splice(this.items.length+1, 0, data);
          }
        } catch (err) {
          alert(err);
        }
      }
      if (row) {
        row.toggleDetails();
      }
      this.appendNew = false;
    },
    
    onDeleteDone(row) {
      let index = this.items.findIndex(item => item.id == row.item.id);
      if (index >= 0) {
        this.items.splice(index, 1);
      }
    },
    
    onRowClick(item) {
      item._showDetails = !item._showDetails;
    },

    onNewClick() {
      this.appendNew = !this.appendNew;
      if (this.appendNew) {
        this.$nextTick(() => {
          const newRowRef = this.$refs.newRowCard;
          newRowRef.focus();
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
